<template>
  <div :class="{ 'hide-nav-cta': hideNavCTA }">
    <navigation landingpage fancy :utm="utm" />

    <section id="landingpage-target" class="hero">
      <div class="hero-body">
        <div class="container pt-6">
          <h1 v-if="manufacturer && keyword" class="title is-size-3-mobile is-size-2 has-text-white-ter mb-2">
            {{ $t(`views.discover.headline.${keyword}`, { manufacturer: manufacturer.label }) }}
          </h1>
          <h1 v-else class="title is-size-3-mobile is-size-2 has-text-white-ter mb-2">
            {{ $t("views.discover.headline.general") }}
          </h1>

          <h3 v-if="manufacturer" class="has-text-grey-lighter is-size-5 highlight-strong"
            v-html="$t(`views.discover.subline`, { count: manufacturer.machineCount, manufacturer: manufacturer.label })" />
          <h3 v-else class="has-text-grey-lighter is-size-5 highlight-strong"
            v-html="$t('views.discover.subline-general')" />

          <div class="is-flex is-flex-wrap-wrap mt-2" style="gap: 0.375rem">
            <span v-for="type in contentTypes" v-bind:key="type" class="px-2 has-text-white custom-label">
              {{ $t(`views.discover.tags.${type}`) }}
            </span>
          </div>
        </div>

        <div class="container mt-6">
          <div class="mt-5 columns is-align-items-center tablet-row-reverse has-horizontal-pull-tablet-only">
            <div class="column is-half is-5-fullhd is-offset-2-fullhd max-width-tablet">
              <div class="card dynamic-card-paddings my-4">
                <h3 class="title is-size-4-widescreen is-size-5 has-text-dark mb-2 mt-3 has-text-centered">
                  {{ $t("views.discover.email-signup.headline") }}
                </h3>

                <form @submit.prevent="submit" class="is-flex mb-5 is-flex-direction-column is-align-items-flex-end mt-5"
                  style="gap: 1rem">
                  <input v-model="email" :class="[
                    'input formelement', { 'is-danger': showValidation && (!email.trim() || !validMail) },
                  ]" type="text" autocomplete="email" :placeholder="$t('views.discover.email-signup.input.label')" />

                  <i18n path="views.discover.email-signup.privacy" class="is-size-7">
                    <a :href="getPrivacyLink()" target="_blank" class="has-text-primary">{{
                      $t("views.register.account.form.terms.privacy")
                    }}</a>
                  </i18n>

                  <button @click.prevent="submit" class="button is-fullwidth is-primary has-text-white">
                    {{ $t("views.discover.email-signup.cta") }}
                  </button>
                </form>

                <footer class="mt-4 columns is-mobile is-multiline is-size-7 is-size-6-fullhd">
                  <div class="column is-flex is-justify-content-center">
                    <div class="is-flex-shrink-0 pr-2">
                      <fa style="color: rgb(124, 233, 162)" icon="lock" />
                    </div>
                    <div class="has-text-grey" style="white-space: nowrap">
                      {{ $t("views.discover.email-signup.security.ssl") }}
                    </div>
                  </div>

                  <div class="column is-flex is-justify-content-center">
                    <div class="is-flex-shrink-0 pr-2">
                      <fa style="color: rgb(124, 233, 162)" icon="shield-alt" />
                    </div>
                    <div class="has-text-grey" style="white-space: nowrap">
                      {{ $t("views.discover.email-signup.security.privacy") }}
                    </div>
                  </div>
                </footer>
              </div>
            </div>

            <div class="column is-half is-5-fullhd">
              <figure id="mock-scroll" style="overflow-y: auto; border-radius: 4px 4px 0 0; aspect-ratio: 16/9;">
                <img src="../../assets/mockup/preview-machine-finder.png" style="margin-bottom: -0.5rem" />
              </figure>

              <div class="card p-4 has-background-dark is-half">
                <h3 class="title is-size-6 is-size-5-widescreen has-text-white mb-2 mt-1 highlight-strong">
                  {{ $t("views.discover.info-box.headline") }}
                </h3>
                <p class="has-text-grey-light is-size-7 is-size-6-widescreen">
                  {{ $t("views.discover.info-box.subline") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="has-background-light">
      <partners />
    </div>
    <features />
    <quote />
    <counseling />

    <footerNavigation />
  </div>
</template>


<script>
import navigation from "../../components/navigation.vue";
import footerNavigation from "../../components/footerNavigation.vue";
import features from "../../components/sections/features.vue";
import partners from "../../components/sections/partners.vue";
import quote from "../../components/sections/quote_new.vue";
import counseling from "../../components/sections/counseling.vue";
import { getManufacturer, getKeyword } from "../../discover-landingpage";
import { store } from "../../store";

export default {
  name: "discover-2024",
  props: {
    utm: Object,
  },
  components: {
    navigation,
    features,
    partners,
    quote,
    counseling,
    footerNavigation,
  },
  data: () => {
    return {
      hideNavCTA: true,
      email: "",
      showValidation: false,
      contentTypes: ["torque", "filling", "service", "hotline", "technical", "parts"],
    };
  },
  computed: {
    selectedPartner() {
      return store.selectedPartner;
    },
    validMail() {
      // NOTE: https://emailregex.com/
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.email
      );
    },
    formValid() {
      return this.email.length && this.validMail;
    },
    registerLink() {
      const query = this.$router.currentRoute.query;
      const params = new URLSearchParams(query);
      params.set("email", this.email);
      return `/register?${params.toString()}`;
    },
    manufacturer() {
      const adGroup =
        this.$router.currentRoute.query?.utm_content?.split("ad-group-")[1];
      return getManufacturer(adGroup);
    },
    keyword() {
      const adGroup =
        this.$router.currentRoute.query?.utm_content?.split("ad-group-")[1];
      return getKeyword(adGroup);
    },
  },
  methods: {
    getPrivacyLink() {
      const TLD = this.selectedPartner?.custom_tld || "eu";
      const ORIGIN = `https://repguide.${TLD}`;
      return `${ORIGIN}/${this.$route.params.locale}/privacy`;
    },
    submit() {
      if (!this.formValid) return (this.showValidation = true);

      // NOTE: no need to await; vue router lets it run in the background
      fetch("https://eocbxa6y7uvac7z.m.pipedream.net", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: this.email }),
      });

      const query = { ...this.$router.currentRoute.query, email: this.email };
      this.$router.push({ name: "register", query });
    },
  },
  mounted() {
    this.hideNavCTA = window.scrollY < 750;
    window.addEventListener("scroll", () => {
      this.hideNavCTA = window.scrollY < 750;
    });

    const FIGURE = document.getElementById("mock-scroll");

    setTimeout(() => {
      FIGURE.scroll({ top: 100, behavior: "smooth" });
    }, 1000);
  },
};
</script>

<style>
.highlight-strong {
  strong {
    color: #e94a10 !important;
  }
}

span.custom-label {
  border-radius: 0.375rem;
  border: 1px solid rgba(92, 92, 92, 1);
  background-color: rgba(62, 62, 62, 0.6);
  backdrop-filter: blur(10px);
  max-height: 2rem;
}

.has-custom-repguide-bullets {
  li {
    padding-left: 2.125rem !important;
  }

  li:before {
    height: 20px !important;
    width: 12px !important;
    top: 0.2rem !important;
    left: 0.625rem !important;
  }
}

@media (min-width: 769px) {
  .tablet-row-reverse {
    flex-direction: row-reverse !important;
  }
}

@media (max-width: 768px) {
  .max-width-tablet {
    max-width: 500px;
    margin: 0 auto;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .has-horizontal-pull-tablet-only {
    margin: 0 -3rem !important;
  }
}

.dynamic-card-paddings {
  padding: 1rem;

  @media (min-width: 769px) {
    padding: 1.5rem;
  }

  @media (min-width: 1216px) {
    padding: 2rem;
  }
}

.hide-nav-cta nav.navbar a.button.is-primary {
  display: none;
}
</style>

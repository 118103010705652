const MANUFACTURERS = [
	{
		key: "fendt",
		label: "Fendt",
		machineCount: 910,
		ids: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
	},
	{
		key: "claas",
		label: "Claas",
		machineCount: 530,
		ids: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
	},
	{
		key: "johnDeere",
		label: "John Deere",
		machineCount: 780,
		ids: [21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
	},
	{
		key: "case",
		label: "Case",
		machineCount: 870,
		ids: [31, 32, 33, 34, 35, 36, 37, 38, 39, 40]
	},
	{
		key: "masseyFerguson",
		label: "Massey Ferguson",
		machineCount: 1200,
		ids: [41, 42, 43, 44, 45, 46, 47, 48, 49, 50]
	},
	{
		key: "deutz",
		label: "Deutz",
		machineCount: 630,
		ids: [51, 52, 53, 54, 55, 56, 57, 58, 59, 60]
	},
	{
		key: "newholland",
		label: "New Holland",
		machineCount: 500,
		ids: [61, 62, 63, 64, 65, 66, 67, 68, 69, 70]
	},
	{
		key: "fiat",
		label: "Fiat",
		machineCount: 130,
		ids: [71, 72, 73, 74, 75, 76, 77, 78, 79, 80]
	},
	{
		key: "kubota",
		label: "Kubota",
		machineCount: 520,
		ids: [81, 82, 83, 84, 85, 86, 87, 88, 89, 90]
	},
	{
		key: "same",
		label: "Same",
		machineCount: 180,
		ids: [91, 92, 93, 94, 95, 96, 97, 98, 99, 100]
	},
	{
		key: "steyr",
		label: "Steyr",
		machineCount: 180,
		ids: [101, 102, 103, 104, 105, 106, 107, 108, 109, 110]
	},
	{
		key: "valtra",
		label: "Valtra",
		machineCount: 120,
		ids: [111, 112, 113, 114, 115, 116, 117, 118, 119, 120]
	},
];

const KEYWORDS = {
	torque: [1, 11, 21, 31, 41, 51, 61, 71, 81, 91, 101, 111, 121], // Anzugswerte
	valve: [2, 12, 22, 32, 42, 52, 62, 72, 82, 92, 102, 112, 122], // Ventilspiel
	oils: [3, 13, 23, 33, 43, 53, 63, 73, 83, 93, 103, 113, 123], // Betriebsstoffe
	service: [4, 14, 24, 34, 44, 54, 64, 74, 84, 94, 104, 114, 124], // Wartungsplan
	filling: [5, 15, 25, 35, 45, 55, 65, 75, 85, 95, 105, 115, 125], // Füllmengen
	technical: [6, 16, 26, 36, 46, 56, 66, 76, 86, 96, 106, 116, 126], // Technische Daten
	zylinderhead: [7, 17, 27, 37, 47, 57, 67, 77, 87, 97, 107, 117, 127], // Zylinderkopf
	engine: [8, 18, 28, 38, 48, 58, 68, 78, 88, 98, 108, 118, 128], // Motoröl
	transmission: [9, 19, 29, 39, 49, 59, 69, 79, 89, 99, 109, 119, 129], // Getriebeöl
	cooling: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130], // Klimaanlage
};

export const getManufacturer = (id) => {
	return MANUFACTURERS.find((manufacturer) =>
		manufacturer.ids.includes(parseInt(id))
	);
};

export const getKeyword = (id) => {
	return Object.keys(KEYWORDS).find((key) =>
		KEYWORDS[key].includes(parseInt(id))
	);
};

import Vue from "vue";
import { eventBus } from "./event-bus";
import { createClient, gql } from "@urql/core";

const FALLBACK_PARTNER = {
  id: 1,
  granit_shop_default: 1,
  GranitShop: {
    base_domain: "granit-parts.com",
  },
  support_contact: "repguide@fricke.de",
  terms: "/agb",
  company: "Wilhelm Fricke SE",
  privacy: "/datenschutz",
  pricing: "/preise",
  contact: "/kontakt",
  vat_number: "DE815646089",
  custom_tld: "de",
  ceos: [
    {
      firstName: "Hartmut",
      lastName: "Klindworth",
    },
  ],
  Pricing: {
    free_period_duration: "P0Y1M0DT0H0M0S",
    termination_period_duration: "P0Y2M0DT0H0M0S",
    invoice_currency: "EUR",
    invoice_amount: 49.9,
    title: "Rep.Guide Basisvertrag",
    tier: "customer-normal",
    contract_key: "STD12",
    PricingOffer: null,
  },
  address: {
    address_line_1: "Zum Kreuzkamp 7",
    address_line_2: null,
    zip_code: "27404",
    city_name: "Heeslingen",
    country: {
      cca2: "DE",
    },
  },
  AppCountry: {
    country_code: "DE",
    countryData: {
      flag: "🇩🇪",
    },
  },
};

const GQL_CLIENT = createClient({
  url: "https://rg-api-yoga-a4b3749-rvuvxilsia-ez.a.run.app",
  requestPolicy: "network-only",
  fetchOptions: () => {
    return { headers: {} };
  },
});

export const store = Vue.observable({
  loading: true,
  partners: [FALLBACK_PARTNER],
  selectedPartner: null,
  AVAILABLE_LOCALES: [
    { code: "de", flag: "🇩🇪", appLanguageId: 1 },
    { code: "en", flag: "🇬🇧", appLanguageId: 2 },
    { code: "nl", flag: "🇳🇱", appLanguageId: 3 },
    { code: "da", flag: "🇩🇰", appLanguageId: 9 },
    { code: "sv", flag: "🇸🇪", appLanguageId: 5 },
    { code: "fi", flag: "🇫🇮", appLanguageId: 16 },
    { code: "no", flag: "🇳🇴", appLanguageId: 25 },
    { code: "fr", flag: "🇫🇷", appLanguageId: 4 },
    { code: "it", flag: "🇮🇹", appLanguageId: 7 },
    { code: "et", flag: "🇪🇪", appLanguageId: 15 },
    { code: "lt", flag: "🇱🇹", appLanguageId: 18 },
    { code: "lv", flag: "🇱🇻", appLanguageId: 19 },
    { code: "cs", flag: "🇨🇿", appLanguageId: 10 },
    { code: "sk", flag: "🇸🇰", appLanguageId: 22 },
    { code: "pl", flag: "🇵🇱", appLanguageId: 6 },
    { code: "es", flag: "🇪🇸", appLanguageId: 8 },
    { code: "pt", flag: "🇵🇹", appLanguageId: 20 },
    { code: "ro", flag: "🇷🇴", appLanguageId: 13 },
    { code: "hu", flag: "🇭🇺", appLanguageId: 4 },
  ],
});

export const mutations = {
  setPartners(partners) {
    store.partners = partners;
  },
  setSelectedPartner(partner) {
    store.selectedPartner = partner;
  },
  setLoading(loading) {
    store.loading = loading;
  },
};

GQL_CLIENT.query(
  gql`
    query Query {
      ContractPartners(isActive: true) {
        id
        support_contact
        granit_shop_default
        GranitShop {
          base_domain
        }
        terms
        company
        privacy
        pricing
        vat_number
        custom_tld
        ceos {
          firstName
          lastName
        }
        Pricing {
          free_period_duration
          termination_period_duration
          invoice_currency
          invoice_amount
          title
          tier
          contract_key
          PricingOffer {
            pricing_key
            valid_until
          }
        }
        address {
          address_line_1
          address_line_2
          zip_code
          city_name
          country {
            cca2
          }
        }
        AppCountry {
          country_code
          countryData {
            flag
          }
        }
      }
    }
  `
)
  .toPromise()
  .then((result) => {
    if (result.errors) throw new Error(result.errors);
    if (!localStorage.getItem("country")) mutations.setSelectedPartner(null);

    mutations.setPartners(result.data.ContractPartners);
    mutations.setLoading(false);

    eventBus.$emit("partners-loaded");
  });

<template>
  <section class="section is-medium" id="partners">
    <div class="container">
      <h2 class="title is-size-2 is-size-4-mobile mt-0">
        {{ $t("views.index.partners.headline") }}
      </h2>
      <p class="mb-6">
        {{ $t("views.index.partners.description") }}
      </p>

      <div class="columns is-justify-content-center is-mobile is-multiline">
        <div v-for="partner in shuffle(partners)" class="column is-3-touch">
          <div :title="partner.name" :class="['partner-logo', partner.class]" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => {
    return {
      partners: [
        {
          name: "Bosch Rexroth",
          class: "has-logo-bosch-rexroth",
        },
        {
          name: "BPW",
          class: "has-logo-bpw",
        },
        {
          name: "Carraro",
          class: "has-logo-carraro",
        },
        {
          name: "Grammer",
          class: "has-logo-grammer",
        },
        {
          name: "GRANIT-Parts",
          class: "has-logo-granit",
        },
        {
          name: "Jaltest",
          class: "has-logo-jaltest",
        },
        {
          name: "Varta",
          class: "has-logo-varta",
        },
        {
          name: "ZF",
          class: "has-logo-zf",
        },
      ],
    };
  },
  methods: {
    shuffle: (array) => {
      const newArr = array.slice();
      for (let i = newArr.length - 1; i > 0; i--) {
        const rand = Math.floor(Math.random() * (i + 1));
        [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
      }
      return newArr;
    },
  },
};
</script>

<style lang="scss">
.partner-logo {
  max-width: 200px;
  aspect-ratio: 2/1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &.has-logo-bosch-rexroth {
    background-image: url("../../assets/logos/boschrexroth.png");
  }

  &.has-logo-bpw {
    background-image: url("../../assets/logos/bpw.png");
  }

  &.has-logo-carraro {
    background-image: url("../../assets/logos/carraro.png");
  }

  &.has-logo-grammer {
    background-image: url("../../assets/logos/grammer.png");
  }

  &.has-logo-granit {
    background-image: url("../../assets/logos/granit.svg");
  }

  &.has-logo-jaltest {
    background-image: url("../../assets/logos/jaltest.svg");
  }

  &.has-logo-varta {
    background-image: url("../../assets/logos/varta.png");
  }

  &.has-logo-zf {
    background-image: url("../../assets/logos/zf.png");
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import { CAMPAIGN_100years, CAMPAIGN_100years_returning } from "./utm";

import index from "./views/_index";
import features from "./views/features";
import meeting from "./views/meeting";
import register from "./views/register";
import registered from "./views/registered";
import returned from "./views/returned";

import freebeer from "./views/landingpages/freebeer";
import discover from "./views/landingpages/discover";

import career from "./views/career";
import service from "./views/service";
import construction from "./views/construction";
import education from "./views/education";
import hotline from "./views/hotline";
import voucher from "./views/voucher";

import contact from "./views/contact";
import impressum from "./views/impressum";
import privacy from "./views/privacy";
import agb from "./views/agb";
import pricing from "./views/pricing";

Vue.use(VueRouter);

const routes = [
  {
    path: "/karriere",
    redirect: "/de/karriere",
  },
  {
    path: "/career",
    redirect: "/en/career",
  },
  {
    path: "/inhalte",
    redirect: "/de/inhalte",
  },
  {
    path: "/features",
    redirect: "/de/features",
  },
  {
    path: "/meeting",
    redirect: "/en/meeting",
  },
  {
    path: "/register",
    redirect: "/de/register",
  },
  {
    path: "/agb",
    redirect: "/de/agb",
  },
  {
    path: "/terms",
    redirect: "/en/terms",
  },
  {
    path: "/preise",
    redirect: "/de/preise",
  },
  {
    path: "/pricing",
    redirect: "/en/pricing",
  },
  {
    path: "/kontakt",
    redirect: "/de/kontakt",
  },
  {
    path: "/contact",
    redirect: "/en/contact",
  },
  {
    path: "/impressum",
    redirect: "/de/impressum",
  },
  {
    path: "/imprint",
    redirect: "/en/imprint",
  },
  {
    path: "/datenschutz",
    redirect: "/de/datenschutz",
  },
  {
    path: "/privacy",
    redirect: "/en/privacy",
  },
  {
    path: "/wartung",
    redirect: "/de/wartung",
  },
  {
    path: "/service",
    redirect: "/en/service",
  },
  {
    path: "/construction",
    redirect: "/de/construction",
  },
  {
    path: "/education",
    redirect: "/de/education",
  },
  {
    path: "/hotline",
    redirect: "/de/hotline",
  },
  {
    path: "/voucher",
    redirect: "/en/voucher",
  },
  {
    path: "/gutscheincode",
    redirect: "/de/gutscheincode",
  },
  {
    path: "/discover",
    redirect: "/en/discover",
  },
  {
    path: "/entdecken",
    redirect: "/de/entdecken",
  },
  {
    path: "/freibier",
    redirect: () => {
      return {
        path: "/de/freibier",
        query: CAMPAIGN_100years,
      };
    },
  },
  {
    path: "/100jahre",
    redirect: () => {
      return {
        path: "/de/freibier",
        query: CAMPAIGN_100years,
      };
    },
  },
  {
    path: "/100-jahre",
    redirect: () => {
      return {
        path: "/de/freibier",
        query: CAMPAIGN_100years,
      };
    },
  },
  {
    path: "/fricke100",
    redirect: () => {
      return {
        path: "/de/gutscheincode",
        query: CAMPAIGN_100years_returning,
      };
    },
  },
  {
    path: "/fricke-100",
    redirect: () => {
      return {
        path: "/de/gutscheincode",
        query: CAMPAIGN_100years_returning,
      };
    },
  },
  {
    path: "/:locale/karriere",
    name: "career",
    component: career,
  },
  {
    path: "/:locale/career",
    name: "career",
    component: career,
  },
  {
    path: "/:locale/inhalte",
    name: "features",
    component: features,
  },
  {
    path: "/:locale/features",
    name: "features",
    component: features,
  },
  {
    path: "/:locale/meeting",
    name: "meeting",
    component: meeting,
  },
  {
    path: "/:locale/register",
    name: "register",
    component: register,
  },
  {
    path: "/:locale/registered",
    name: "registered",
    component: registered,
  },
  {
    path: "/:locale/returned",
    name: "returned",
    component: returned,
  },
  {
    path: "/:locale/agb",
    name: "agb",
    component: agb,
  },
  {
    path: "/:locale/terms",
    name: "terms",
    component: agb,
  },
  {
    path: "/:locale/preise",
    name: "pricing",
    component: pricing,
  },
  {
    path: "/:locale/pricing",
    name: "pricing",
    component: pricing,
  },
  {
    path: "/:locale/contact",
    name: "contact",
    component: contact,
  },
  {
    path: "/:locale/kontakt",
    name: "contact",
    component: contact,
  },
  {
    path: "/:locale/impressum",
    name: "impressum",
    component: impressum,
  },
  {
    path: "/:locale/imprint",
    name: "imprint",
    component: impressum,
  },
  {
    path: "/:locale/datenschutz",
    name: "privacy",
    component: privacy,
  },
  {
    path: "/:locale/privacy",
    name: "privacy",
    component: privacy,
  },
  {
    path: "/:locale/wartung",
    name: "service",
    component: service,
  },
  {
    path: "/:locale/service",
    name: "service",
    component: service,
  },
  {
    path: "/:locale/construction",
    name: "construction",
    component: construction,
  },
  {
    path: "/:locale/education",
    name: "education",
    component: education,
  },
  {
    path: "/:locale/hotline",
    name: "hotline",
    component: hotline,
  },
  {
    path: "/:locale/gutscheincode",
    name: "voucher",
    component: voucher,
  },
  {
    path: "/:locale/voucher",
    name: "voucher",
    component: voucher,
  },
  {
    path: "/:locale/entdecken",
    name: "discover-now",
    component: discover,
  },
  {
    path: "/:locale/discover",
    name: "discover-now",
    component: discover,
  },
  {
    path: "/:locale/freibier",
    name: "100-jahre-fricke",
    component: freebeer,
  },
  {
    path: "/:locale/100jahre",
    redirect: () => {
      return {
        path: "/:locale/freibier",
        query: CAMPAIGN_100years,
      };
    },
  },
  {
    path: "/:locale/100-jahre",
    redirect: () => {
      return {
        path: "/:locale/freibier",
        query: CAMPAIGN_100years,
      };
    },
  },
  {
    path: "/:locale/fricke100",
    redirect: () => {
      return {
        path: "/:locale/voucher",
        query: CAMPAIGN_100years_returning,
      };
    },
  },
  {
    path: "/:locale/fricke-100",
    redirect: () => {
      return {
        path: "/:locale/voucher",
        query: CAMPAIGN_100years_returning,
      };
    },
  },
  {
    path: "/:locale",
    name: "index",
    component: index,
  },
  {
    path: "/",
    redirect: "/de",
  },
  {
    path: "*",
    name: "404",
    redirect: "/",
  },
];
const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes,
});

const hasQueryParams = (route) => !!Object.keys(route.query).length;

router.beforeEach((to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ name: to.name, query: from.query });
  } else {
    next();
  }
});

export default router;

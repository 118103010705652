const rgLogo = {
	prefix: "rg",
	iconName: "logo",
	icon: [
		139,
		239,
		[],
		null,
		"M123.21 69.13L138.61 0H53.09l-15.4 69.13zM15.24 169.91L0 238.33h85.52l15.25-68.42zM104.76 151.99l14.46-64.94H33.7l-14.47 64.94z",
	],
};

import Vue from "vue";
import router from "./router.js";
import VueGtm from "vue-gtm";
import VueI18n from "vue-i18n";
import { store } from "./store";
import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faUserSecret,
	faHeart,
	faCoffee,
	faCheck,
	faChevronDown,
	faChevronRight,
	faEnvelope,
	faUser,
	faBuilding,
	faMapPin,
	faCog,
	faPause,
	faExclamationTriangle,
	faQuoteRight,
	faAngleDoubleRight,
	faAngleDoubleDown,
	faMouse,
	faSignInAlt,
	faExternalLinkAlt,
	faBook,
	faLaptop,
	faTractor,
	faStopwatch,
	faMobile,
	faExclamation,
	faSquare,
	faInfo,
	faStar,
	faFileContract,
	faEuroSign,
	faThumbsUp,
	faCircle,
	faLock,
	faClock,
	faPrint,
	faDatabase,
	faOilCan,
	faPlusCircle,
	faMinusCircle,
	faGift,
	faParagraph,
	faCalendarWeek,
	faGlobe,
	faFilePdf,
	faHeadset,
	faTimes,
	faArrowRight,
	faShieldAlt
} from "@fortawesome/free-solid-svg-icons";
import {
	faLinkedin,
	faFacebookSquare,
	faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons";
import {
	FontAwesomeIcon,
	FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";
require("typeface-lato");
require("typeface-work-sans");

library.add(faUserSecret);
library.add(faHeart);
library.add(faCoffee);
library.add(faCheck);
library.add(faPrint);
library.add(faDatabase);
library.add(faOilCan);
library.add(faPlusCircle);
library.add(faMinusCircle);
library.add(faClock);
library.add(faChevronDown);
library.add(faChevronRight);
library.add(faEnvelope);
library.add(faUser);
library.add(faBuilding);
library.add(faMapPin);
library.add(faCog);
library.add(faExclamationTriangle);
library.add(faQuoteRight);
library.add(faAngleDoubleRight);
library.add(faAngleDoubleDown);
library.add(faMouse);
library.add(faPause);
library.add(faSignInAlt);
library.add(faExternalLinkAlt);
library.add(faBook);
library.add(faLaptop);
library.add(faTractor);
library.add(rgLogo);
library.add(faStopwatch);
library.add(faMobile);
library.add(faExclamation);
library.add(faSquare);
library.add(faInfo);
library.add(faStar);
library.add(faFileContract);
library.add(faEuroSign);
library.add(faThumbsUp);
library.add(faCircle);
library.add(faLock);
library.add(faGift);
library.add(faParagraph);
library.add(faCalendarWeek);
library.add(faGlobe);
library.add(faFilePdf);
library.add(faHeadset);
library.add(faTimes);
library.add(faArrowRight);
library.add(faLinkedin);
library.add(faFacebookSquare);
library.add(faInstagramSquare);
library.add(faShieldAlt);

Vue.component("fa", FontAwesomeIcon);
Vue.component("fa-layers", FontAwesomeLayers);

Vue.config.productionTip = false;

Vue.use(VueGtm, {
	id: "GTM-NPJ6QJW", // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
	enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
	debug: false, // Whether or not display console logs debugs (optional)
	loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
	vueRouter: router, // Pass the router instance to automatically sync with router (optional)
});

Vue.use(VueI18n);

import de from "../locales/de-DE.json";
import en from "../locales/en-GB.json";
import nl from "../locales/nl-NL.json";
import da from "../locales/da-DK.json";
import sv from "../locales/sv-SE.json";
import fi from "../locales/fi-FI.json";
import no from "../locales/no-NO.json";
import cs from "../locales/cs-CZ.json";
import sk from "../locales/sk-SK.json";
import fr from "../locales/fr-FR.json";
import pl from "../locales/pl-PL.json";
import it from "../locales/it-IT.json";
import et from "../locales/et-EE.json";
import lt from "../locales/lt-LT.json";
import lv from "../locales/lv-LV.json";
import es from "../locales/es-ES.json";
import pt from "../locales/pt-PT.json";
import ro from "../locales/ro-RO.json";
import hu from "../locales/hu-HU.json";

const i18n = new VueI18n({
	// NOTE: locale moved to L186
	messages: {
		de,
		en,
		nl,
		da,
		sv,
		fi,
		no,
		cs,
		sk,
		fr,
		pl,
		it,
		et,
		lt,
		lv,
		es,
		pt,
		ro,
		hu,
	}, // set locale messages
	fallbackLocale: "de",
	globalInjection: true,
	formatFallbackMessages: true,
});

const NAVIGATOR_CODE = navigator.language || navigator.userLanguage;
const NAVIGATOR_LOCALE = store.AVAILABLE_LOCALES.find(
	(locale) => locale.code === NAVIGATOR_CODE?.split("-")?.[0]
);

const SEARCH_CODE = new URLSearchParams(window.location.search).get("locale");
const SEARCH_LOCALE = store.AVAILABLE_LOCALES.find(
	(locale) => locale.code === SEARCH_CODE
);

const LOCALE =
	// NOTE: user already explicitly set a locale
	localStorage.getItem("locale") ||
	// NOTE: search parameter locale is set (likely by a redirect)
	SEARCH_LOCALE?.code ||
	// NOTE: navigator locale is set (browser language setting)
	NAVIGATOR_LOCALE?.code ||
	// NOTE: fall back to default locale "en"
	"en";

// NOTE: using the locale "en" will cause i18n to use its default value "en-US"
// NOTE: setting the locale after initialization will work as expected though
// LINK: https://github.com/kazupon/vue-i18n/issues/579
i18n.locale = "de";
Sentry.init({
	Vue,
	dsn: "https://1e740a1cfc4e4ab58a9a8e4e523114d9@o948531.ingest.sentry.io/6621435",
	enabled: true,
});

new Vue({
	router,
	i18n,
	render: (h) => h(App),
}).$mount("#app");
